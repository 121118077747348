<template>
	<v-app class="main">
		<div class="groups-table">
			<!-- dropdown for project configuration form -->
			<v-card-actions style='background: #eeeeee; height:50px; margin: auto; margin-bottom:20px;'>
				<v-card-text color="black" style = 'font-weight:bold;' text>
					Projects
				</v-card-text>

				<v-spacer></v-spacer>

				<v-btn icon @click="projectsConfiguration.visible = !projectsConfiguration.visible">
					<v-icon>{{ projectsConfiguration.visible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</v-btn>
			</v-card-actions>

			<div v-if="projectsConfiguration.visible">
			<!-- Project list -->
				<v-list
					class="table"
				>
					<v-list-item
						class="table-header-list-item"
					>
						<div class="table-row-wrapper permissions-header">
							<div class="table-wrapper-item table-wrapper-item-left">
								<!-- {{ lview.headerName }} -->
								Name
							</div>
							<div class="table-wrapper-item">
									<!-- {{ lview.headerActions }} -->
									Actions
							</div>
						</div>
					</v-list-item>

					<template
						v-for="item in projectsConfiguration.projects"
					>
						<v-list-item
							class="table-item py-1"
							v-bind:key="item.id"
						>
							<div class="table-row-wrapper">
								<div class="table-wrapper-item table-wrapper-item-left">
									{{ item.name }}
								</div>
								<div class="table-wrapper-item">
									<div style="display:flex;">
										<!-- Desktop -->
										<template v-if="windowWidth > 450" >
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="projectsConfiguration.displayEdit"
														class="mr-2"
														color="primary"
														v-bind="attrs"
														v-on="on"
														@click="() => { editProject(item); }"
														icon
														text
														small
													>
														<v-icon>
															mdi-pencil
														</v-icon>
													</v-btn>
												</template>
												<span>Edit</span>
											</v-tooltip>

											<div
												v-if="projectsConfiguration.displayEdit"
												:class="item.active? 'red--text mr-2': 'green--text mr-2'"
												style="display:flex; align-items: center; cursor: pointer;"													@click="changeProjectStatus(item)"
											>
												<v-icon :class="item.active? 'red--text mr-1': 'green--text mr-1'">
													mdi-history
												</v-icon>
												<span v-if="item.active">Deactivate</span>
												<span v-else>Activate</span>
											</div>			
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="projectsConfiguration.displayDelete && item.deletable"
														icon
														text
														@click="deleteProject(item);"
														v-bind="attrs"
														v-on="on"
														color="red"
														small
													>
														<v-icon>
															mdi-delete
														</v-icon>
													</v-btn>
												</template>
												<span>Delete</span>
											</v-tooltip>
										</template>

										<!-- Mobile -->
										<template v-else>
											<v-menu>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														text
														fab
														ripple
														small
														v-bind="attrs"
														v-on="on"
														style="margin: 0 auto"
													>
														<v-icon color="primary">
															mdi-dots-vertical
														</v-icon>
													</v-btn>
												</template>

												<v-list>
													<v-list-item
														v-if="projectsConfiguration.displayEdit"
														@click="() => { editProject(item); }"
													>
														<v-icon class="primary--text" style="padding-right:5px">mdi-pencil</v-icon>
														{{lviews.edit}}
													</v-list-item>

													<v-list-item 
														v-if="projectsConfiguration.displayEdit"
														:class="item.active? 'red--text': 'green--text'"
														@click="changeProjectStatus(item)"
													>
														<v-icon :class="item.active? 'red--text': 'green--text'" style="padding-right:5px">mdi-history</v-icon>
														<v-list-item-title v-if="item.active">Deactivate</v-list-item-title>
														<v-list-item-title v-else>Activate</v-list-item-title>
													</v-list-item>

													<v-list-item style="color:red"
														v-if="projectsConfiguration.displayDelete && item.deletable"
														@click="deleteProject(item);"
													>
														<v-icon style="padding-right:5px; color: red">mdi-delete</v-icon>
														{{lviews.delete}}
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
									</div>
								</div>
							</div>
						</v-list-item>
					</template>
				</v-list>

				<div style="text-align:end">
					<v-btn
						fab
						dark
						small
						style="margin-right: 20px; margin-top: 10px;"
						v-if="projectsConfiguration.displayAdd"
						color="orange"
						@click="addProject()"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
			</div>	
		</div>

		<div class="groups-table">
			<!-- dropdown for leave types configuration form -->
			<v-card-actions style='background: #eeeeee; height:50px; margin: auto; margin-bottom:20px;'>
				<v-card-text color="black" style = 'font-weight:bold;' text>
					Leave types
				</v-card-text>

				<v-spacer></v-spacer>

				<v-btn icon @click="leaveTypesConfiguration.visible = !leaveTypesConfiguration.visible">
					<v-icon>{{ leaveTypesConfiguration.visible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</v-btn>
			</v-card-actions>

			<div v-if="leaveTypesConfiguration.visible">
			<!-- Leave types list -->
			<v-list
				class="table"
				>
					<v-list-item
						class="table-header-list-item"
					>
						<div class="table-row-wrapper permissions-header">
							<div class="table-wrapper-item table-wrapper-item-left">
								<!-- {{ lview.headerName }} -->
								Name
							</div>
							<div class="table-wrapper-item">
								<!-- {{ lview.headerActions }} -->
								Actions
							</div>
						</div>
					</v-list-item>

					<template
						v-for="item in leaveTypesConfiguration.items"
					>
						<v-list-item
							class="table-item py-1"
							v-bind:key="item.id"
						>
							<div class="table-row-wrapper">
								<div class="table-wrapper-item table-wrapper-item-left">
									{{ item.name }}
								</div>
								<div class="table-wrapper-item">
									<div style="display:flex;">
										<!-- Desktop -->
										<template v-if="windowWidth > 450">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="leaveTypesConfiguration.displayEdit"
														class="mr-2"
														color="primary"
														v-bind="attrs"
														v-on="on"
														@click="() => { editLeaveType(item); }"
														icon
														text
														small
													>
														<v-icon>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Edit</span>
											</v-tooltip>
											<div
												v-if="leaveTypesConfiguration.displayEdit"
												:class="item.active? 'red--text mr-2': 'green--text mr-2'"
												style="display:flex; align-items: center; cursor: pointer;"
												@click="changeLeaveTypeStatus(item)"
											>
												<v-icon :class="item.active? 'red--text mr-1': 'green--text mr-1'">
													mdi-history
												</v-icon>
												<span v-if="item.active">Deactivate</span>
												<span v-else>Activate</span>
											</div>			
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-if="leaveTypesConfiguration.displayDelete && item.deletable"
														icon
														text
														@click="deleteLeaveType(item);"
														v-bind="attrs"
														v-on="on"
														color="red"
														small
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</template>
												<span>{{ lviews.delete }}</span>
											</v-tooltip>
										</template>

										<!-- Mobile -->
										<template v-else>
											<v-menu>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														text
														fab
														ripple
														small
														v-bind="attrs"
														v-on="on"
														style="margin: 0 auto"
													>
														<v-icon color="primary">
															mdi-dots-vertical
														</v-icon>
													</v-btn>
												</template>

												<v-list>
													<v-list-item
														v-if="leaveTypesConfiguration.displayEdit"
														@click="() => { editLeaveType(item); }"
													>
														<v-icon class="primary--text" style="padding-right:5px">mdi-pencil</v-icon>
														Edit
													</v-list-item>

													<v-list-item
														v-if="leaveTypesConfiguration.displayEdit"
														@click="changeLeaveTypeStatus(item)"
														:class="item.active? 'red--text': 'green--text'"
													>
														<v-icon :class="item.active? 'red--text': 'green--text'" style="padding-right:5px">mdi-history</v-icon>
														<v-list-item-title v-if="item.active">Deactivate</v-list-item-title>
														<v-list-item-title v-else>Activate</v-list-item-title>
													</v-list-item>

													<v-list-item style="color:red"
														v-if="leaveTypesConfiguration.displayDelete && item.deletable"
														@click="deleteLeaveType(item)"
													>
														<v-icon style="padding-right:5px; color: red">mdi-delete</v-icon>
														{{ lviews.delete }}
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
									</div>
								</div>
							</div>
						</v-list-item>
					</template>
				</v-list>

				<div style="text-align:end">
					<v-btn
						fab
						dark
						small
						style="margin-right: 20px; margin-top: 10px; margin-bottom: 20px;"
						v-if="leaveTypesConfiguration.displayAdd"
						color="orange"
						@click="addLeaveType()"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
			</div>
		</div>

		<!-- Dialog to delete project -->
		<v-dialog
			v-model="deleteProjectDialog.visible"
			width="350px"
			persistent
		>
			<v-card >
				<v-card-title>
					<span> Delete project </span>
				</v-card-title>

				<v-card-text>
					<span> Are you sure you want to delete project? </span>
					<br/><br/>
					<strong> Project name: </strong> <div> {{deleteProjectDialog.item.name}} </div>
				</v-card-text>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="closeProjectDelete" text>
						{{lviews.cancel}}
					</v-btn>
					<v-btn @click="deleteProjectConfirm(deleteProjectDialog.item)" color="red" text>
						<strong> Delete </strong>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<!-- new project edit dialog -->
		<v-dialog
			v-model="editProjectDialog.visible"
			persistent
			width="600"
		>
			<v-card>
				<v-card-title v-if="editProjectDialog.item" class="text-h6">
					Edit project
				</v-card-title>
				<v-card-title v-else class="text-h6">
					New project
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text class="mt-5">
					<v-select
						v-model="editProjectDialog.modifiedItem.active"
						:items="dropdownStatus"
						item-text="name"
						item-value="value"
						label="Status"
						style="margin: auto 20px;"
						class="required"
					></v-select>

					<v-select
						v-model="editProjectDialog.modifiedItem.timezone"
						:items="dropdownTimezone"
						item-text="label"
						item-value="tzCode"
						label="Timezone"
						style="margin: auto 20px;"
						class="required"
					></v-select>
					<v-text-field
						v-model="editProjectDialog.modifiedItem.name"
						label="Project name"
						style="margin: auto 20px;"
						clearable
						:rules="editProjectDialog.rules.name"
						maxlength="100"
						counter
						class="required"
					></v-text-field>

					<v-text-field
						v-model="editProjectDialog.modifiedItem.description"
						label="Project description"
						style="margin-left: 30px; margin-right: 20px;"
						clearable
						maxlength="500"
						counter
					></v-text-field>
				</v-card-text>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						@click="cancelEditingProject"
					>
						{{ lviews.cancel }}
					</v-btn>

					<v-btn
						color="primary"
						@click="editProjectCallback"
						:disabled="editProjectDialog.errors.name"
					>
						{{ lviews.save }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Dialog to delete leave type -->
		<v-dialog
			v-model="deleteLeaveTypeDialog.visible"
			width="350px"
			persistent
		>
			<v-card >
				<v-card-title>
					<span> Delete leave type </span>
				</v-card-title>

				<v-card-text>
					<span> Are you sure you want to delete leave type? </span>
					<br/><br/>
					<strong> Leave type name: </strong> <div> {{deleteLeaveTypeDialog.item.name}} </div>
				</v-card-text>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="closeLeaveTypeDelete" text>
						{{lviews.cancel}}
					</v-btn>
					<v-btn @click="deleteLeaveTypeConfirm(deleteLeaveTypeDialog.item)" color="red" text>
						<strong> Delete </strong>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- new leave type edit dialog -->
		<v-dialog
			v-model="editLeaveTypeDialog.visible"
			persistent
			width="600"
		>
			<v-card>
				<v-card-title v-if="editLeaveTypeDialog.item" class="text-h6">
					Edit leave type
				</v-card-title>
				<v-card-title v-else class="text-h6">
					New leave type
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text class="mt-5">
					<v-select
						v-model="editLeaveTypeDialog.modifiedItem.active"
						:items="dropdownStatus"
						item-text="name"
						item-value="value"
						label="Status"
						style="margin: auto 20px;"
						class="required"
					></v-select>

					<v-text-field
						v-model="editLeaveTypeDialog.modifiedItem.name"
						label="Name"
						style="margin: auto 20px;"
						clearable
						:rules="editLeaveTypeDialog.rules.name"
						maxlength="100"
						counter
						class="required"
					></v-text-field>

					<div style="display:flex;">
						<v-text-field
							v-model="editLeaveTypeDialog.modifiedItem.code"
							label="Code"
							style="margin: auto 20px;"
							clearable
							:rules="editLeaveTypeDialog.rules.code"
							maxlength="20"
							counter
							class="required"
						></v-text-field>

						<v-text-field
							v-model="editLeaveTypeDialog.modifiedItem.description"
							label="Description"
							style="margin: auto 20px;"
							clearable
							maxlength="500"
							counter
						></v-text-field>
					</div>

					<div style="display:flex; justify-content: space-between; align-items: center; margin: 20px 20px 5px 20px;">
						<span class="black--text">Color</span>

						<div class="colorFieldWrapper">
							<v-text-field v-model="editLeaveTypeDialog.modifiedItem.color" hide-details class="ma-0 pa-0" solo>
								<template v-slot:append>
									<v-menu v-model="editLeaveTypeDialog.colorPicker" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
										<template v-slot:activator="{ on }">
											<div :style="swatchStyle(editLeaveTypeDialog.modifiedItem.color)" v-on="on" />
										</template>
										<v-card>
											<v-card-text class="pa-0">
												<v-color-picker v-model="editLeaveTypeDialog.modifiedItem.color" flat />
											</v-card-text>
										</v-card>
									</v-menu>
								</template>
							</v-text-field>
						</div>
					</div>

					<div style="display:flex; justify-content: space-between; align-items: center; margin: 5px 20px;">
						<span class="black--text">Background color</span>

						<div class="colorFieldWrapper">
							<v-text-field v-model="editLeaveTypeDialog.modifiedItem.bgcolor" hide-details class="ma-0 pa-0" solo>
								<template v-slot:append>
									<v-menu v-model="editLeaveTypeDialog.bgcolorPicker" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
										<template v-slot:activator="{ on }">
											<div :style="swatchStyle(editLeaveTypeDialog.modifiedItem.bgcolor)" v-on="on" />
										</template>
										<v-card>
											<v-card-text class="pa-0">
												<v-color-picker v-model="editLeaveTypeDialog.modifiedItem.bgcolor" flat />
											</v-card-text>
										</v-card>
									</v-menu>
								</template>
							</v-text-field>
						</div>
					</div>
				</v-card-text>
				
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						@click="cancelEditingLeaveType"
					>
						{{ lviews.cancel }}
					</v-btn>

					<v-btn
						color="primary"
						@click="editLeaveTypeCallback"
						:disabled="editLeaveTypeDialog.errors.name || editLeaveTypeDialog.errors.code"
					>
						{{ lviews.save }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<!-- configuration error dialogs -->
		<v-dialog
			v-model="errorDialog.visible"
			persistent
			max-width="350px"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.title }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.content }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn
						text
						@click="closeErrorDialog"
					>
						{{ lviews.understand }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- API call notification snackbar -->
		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
			max-width="400px"
			timeout="1500"
		>
			{{ snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar.visible = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
//import axios from 'axios';
const axios = require('axios');
import appConfig from '@/app_config'
import timezones from 'timezones-list';

export default {
	name: 'Configuration',
	data() {
		return{
			permissions: [],
			windowWidth: window.innerWidth,
			snackbar: {
				visible: false,
				color: null,
				message: "",
			},

			projectsConfiguration: {
				visible: true,
				newDocumentType: '',
				newTypeValid: false,
				projects: [
					/**
					 * {
					 *      name     : {String} - name of document type
					 *      id       : {Number} - document type identifier (in frontend)
					 *      disabled : {Boolean} - information whether document type is disabled or not
					 * }
					 */
				],
				sourceData: [
					/**
					 * {
					 *      name     : {String} - name of document type
					 *      id       : {Number} - document type identifier (in frontend)
					 *      disabled : {Boolean} - information whether document type is disabled or not
					 * }
					 */
				],
				displayEdit: true,
				displayDelete: true,
				displayAdd: true,
			},

			// edit document value
			editProjectDialog: {
				visible: false,
				item: null,

				modifiedItem: {
					name: null,
					active: null,
					description: null,
					timezone: null
				},
				errors: {
					name: false,
				},
				errorMessages: {
					name: "",
				},

				// edit dialog fields' validation rules
				rules: {
					name: [] // assigned in mounted()
				}
			},

			deleteProjectDialog: {
				visible: false,
				item: {
					name: null
				}
			},

			deleteLeaveTypeDialog: {
				visible: false,
				item: {
					name: null
				}
			},

			leaveTypesConfiguration: {
				visible: true,
				newLeaveType: '',
				newTypeValid: false,
				items: [
					/**
					 * {
					 *      name     : {String} - name of document type
					 *      id       : {Number} - document type identifier (in frontend)
					 *      disabled : {Boolean} - information whether document type is disabled or not
					 * }
					 */
				],
				sourceData: [
					/**
					 * {
					 *      name     : {String} - name of document type
					 *      id       : {Number} - document type identifier (in frontend)
					 *      disabled : {Boolean} - information whether document type is disabled or not
					 * }
					 */
				],
				displayEdit: true,
				displayDelete: true,
				displayAdd: true,
			},

			// edit document value
			editLeaveTypeDialog: {
				colorPicker: false,
				bgcolorPicker: false,
				visible: false,
				item: null,

				modifiedItem: {
					name: null,
					code: null,
					active: null,
					description: null,
					bgcolor: '#eeeeee',
					color: '#cccccc'
				},
				errors: {
					name: false,
					code: false,
				},
				errorMessages: {
					name: "",
					code: ""
				},

				// edit dialog fields' validation rules
				rules: {
					name: [], // assigned in mounted()
					code: []
				}
			},

			dropdownStatus: [
				{
					name: "Active",
					value: true
				},
				{
					name: "Inactive",
					value: false
				},
			],

			dropdownTimezone: timezones,

			errorDialog: {
				visible: false,
				title: "",
				content: "",
			}
		};
	},
	mounted: async function() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
		this.$emit("set-state", "default");
		// this.$emit('set-display', "BACK_WINDOW");
		this.$emit('set-title', this.lviews.configurationTitle);

		//setting permissions
		if(localStorage.getItem('permissions') !== null){
			this.permissions = JSON.parse(localStorage.permissions);
		}

		if (!this.permissions.includes("admin_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return
		}

		if (!this.permissions.includes("admin_view")) {
			this.projectsConfiguration.displayAdd=false
			this.leaveTypesConfiguration.displayAdd=false
		}

		if (!this.permissions.includes("admin_add")) {
			this.projectsConfiguration.displayAdd=false
			this.leaveTypesConfiguration.displayAdd=false
		}

		if (!this.permissions.includes("admin_change")) {
			this.projectsConfiguration.displayEdit=false
			this.leaveTypesConfiguration.displayEdit=false
		} 

		if (!this.permissions.includes("admin_delete")) {
			this.projectsConfiguration.displayDelete=false
			this.leaveTypesConfiguration.displayDelete=false
		}

		this.editProjectDialog.rules.name = [
			v => ( this.editProjectNameRule(v) ) || this.editProjectDialog.errorMessages.name
		];

		this.editLeaveTypeDialog.rules.name = [
			v => ( this.editLeaveTypeNameRule(v) ) || this.editLeaveTypeDialog.errorMessages.name
		];

		this.editLeaveTypeDialog.rules.code = [
			v => ( this.editLeaveTypeCodeRule(v) ) || this.editLeaveTypeDialog.errorMessages.code
		];

		let projectsData = await this.fetchProjectsData();
		if (projectsData != null){
			this.adaptProjectsData(projectsData);
		}

		let leaveTypesData = await this.fetchLeaveTypesData();
		if (leaveTypesData != null){
			this.adaptLeaveTypesData(leaveTypesData);
		}
	},
	computed: {
		lviews: {
			get: function() {
				return this.$t('views.configurationPanel');
			}
		},
		userHasAddPermission: {
			get: function() {
				// let userPermissions = localStorage.getItem("user_permissions");
				// return userPermissions.includes("fob.admin_add");
                return true
			}
		},
		userHasChangePermission: {
			get: function() {
				// let userPermissions = localStorage.getItem("user_permissions");
				// return userPermissions.includes("fob.admin_change");
                return true
			}
		},
		userHasDeletePermission: {
			get: function() {
				// let userPermissions = localStorage.getItem("user_permissions");
				// return userPermissions.includes("fob.admin_delete");
                return true
			}
		}
	},
	methods: {
		// PROJECTS METHODS

		/**
		 * Adapts response data received from APIs:
		 *  -> ( link: api/v1/admin/projects/ | method: GET )
		 *  -> ( link: api/v1/admin/projects/ | method: POST )
		 *  -> ( link: api/v2/admin/projects/{project_id} | method: PATCH )
		 * @param responseData: {Object}
		 */
		adaptProjectsData: function (responseData) {
			this.projectsConfiguration.projects = [];
			this.projectsConfiguration.sourceData = [];

			for(let i = 0; i < responseData.length; i++) {
				this.projectsConfiguration.projects.push({
					id: responseData[i].id,
					name: responseData[i].name,
                    description: responseData[i].description,
                    timezone: responseData[i].timezone,
                    active: responseData[i].status==0? true: false,
					deletable: true //do obsłużenia
				});

				this.projectsConfiguration.sourceData.push({
					id: responseData[i].id,
					name: responseData[i].name,
                    description: responseData[i].description,
                    timezone: responseData[i].timezone,
                    active: responseData[i].status==0? true: false,
					deletable: true //do obsłużenia
				});
			}
		},

		// fetches projects data from database
		fetchProjectsData: async function() {
			let responsePromise = axios({
				method: "GET",
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/projects/",
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});

			let response = null;
			this.$emit("set-state", "LOADING_OVERLAYER");
			try {
				response = await responsePromise;
			} catch (error) {
				this.$emit("set-state", "default");
				this.handleError(error)
			}
			this.$emit("set-state", "default");
			return response.data;
		},

		saveProjectChanges(project){
			let data={
				"status": project.active? 0: 1,
				"timezone": project.timezone,
				"description": project.description,
			}
			if(project.name!=null && project.name!=""){
				data.name=project.name
			}
			this.$emit("set-state", "LOADING_OVERLAYER", "Saving changes...");
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/projects/"+project.id,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: data
				}).then(() => {
					this.$emit("set-state", "default");
					this.snackbar.color = 'success';
					this.snackbar.message = "Project updated sucessfully";
					this.snackbar.visible = true;
					this.reloadProjectsData()
				})
				.catch((error) => {
					this.$emit("set-state", "default");
					this.handleError(error)
			})
		},

		addNewProject(project){
			let data={
				"status": project.active? 0: 1,
				"timezone": project.timezone,
				"description": project.description,
				"name": project.name
			}
			this.$emit("set-state", "LOADING_OVERLAYER", "Creating project...");
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/projects/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: data
				}).then(() => {
					this.$emit('set-state', 'default');

					this.snackbar.color = 'success';
					this.snackbar.message = "Project added sucessfully";
					this.snackbar.visible = true;
					this.reloadProjectsData()
				})
				.catch((error) => {
					this.$emit("set-state", "default");
					this.handleError(error)
			})
		},

		changeProjectStatus(project){
			this.editProjectDialog.item = project;
			this.editProjectDialog.modifiedItem.name = project.name;
			this.editProjectDialog.modifiedItem.active = !project.active;
			this.editProjectDialog.modifiedItem.timezone = project.timezone;
			this.editProjectDialog.modifiedItem.description = project.description;
			this.editProjectCallback()
		},

		deleteProject (item) {
			this.deleteProjectDialog.item=item
			this.deleteProjectDialog.visible=true
		},

		closeProjectDelete () {
			this.deleteProjectDialog.item={
				name: null
			}
			this.deleteProjectDialog.visible=false
		},


		// deleteDocumentType: function(documentType){
		// 	if (documentType.id == -1) {
		// 		let idx = this.projectsConfiguration.projects.findIndex(x => { return x === documentType; }); // reference check
		// 		this.projectsConfiguration.documentTypes.splice(idx, 1);
		// 	}
		// 	else {
		// 		documentType.delete = !documentType.delete;
		// 	}
		// },

		deleteProjectConfirm: function(item){
			this.$emit("set-state", "LOADING_OVERLAYER", "Removing project...");
				axios({
					method: "DELETE",
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/projects/" + item.id,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
				}).then(()=>{
					this.$emit("set-state", "default");
					this.snackbar.color = 'success';
					this.snackbar.message = "Project removed sucessfully";
					this.snackbar.visible = true;
					this.reloadProjectsData()
					this.closeProjectDelete()
				}).catch ((error)=> {
					this.$emit("set-state", "default");
					this.handleError(error)
					this.closeProjectDelete();
					return;
				})
		},

		

		/**
		 * Edits existing/already added project
		 * @param project - project to edit
		 */
		editProject: function(project) {
			this.editProjectDialog.item = project;
			this.editProjectDialog.modifiedItem.name = project.name;
			this.editProjectDialog.modifiedItem.active = project.active;
			this.editProjectDialog.modifiedItem.timezone = project.timezone;
			this.editProjectDialog.modifiedItem.description = project.description;
			this.editProjectDialog.visible = true;
		},

		addProject(){
			this.editProjectDialog.modifiedItem.active = true;
			this.editProjectDialog.modifiedItem.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			this.editProjectDialog.visible = true;
			if(this.editProjectDialog.modifiedItem.name==''||this.editProjectDialog.modifiedItem.name==null){
				this.editProjectDialog.errors.name=true
			}
		},

		// Resets edit project dialog and closes it
		cancelEditingProject: function() {
			this.editProjectDialog.item = null;
			if(this.editProjectDialog.modifiedItem.name==null){
				this.editProjectDialog.modifiedItem = {
					name: ""
				}
			}
			else{
				this.editProjectDialog.modifiedItem = {
					name: null
				};
			}
			
			this.editProjectDialog.visible = false;
		},

		// Edit project callback
		// Closes project edit dialog and modifies project data
		editProjectCallback: function() {
			let modified = this.editProjectDialog.modifiedItem;
			// if there's any error, we cannot update project
			if (this.editProjectDialog.errors.name) {
				return;
			}

			//save modified project
			if(this.editProjectDialog.item){
				modified.id=this.editProjectDialog.item.id
				if(this.editProjectDialog.item.name==modified.name){
					modified.name=null
				}
				// else{
				// 	this.editProjectDialog.item.name=modified.name
				// }
				// this.editProjectDialog.item.active=modified.active
				// this.editProjectDialog.item.timezone=modified.timezone
				// this.editProjectDialog.item.description=modified.description
				this.saveProjectChanges(modified)
			}
			//add new project
			else{
				this.addNewProject(modified)
			}

			// reset dialog data and close it
			this.editProjectDialog.item = null;
			this.editProjectDialog.modifiedItem = {
				name: null,
				active: null,
				description: null,
				timezone: null
			};
			this.editProjectDialog.errors = {
				name: false,
			}

			this.editProjectDialog.visible = false;
		},

		// project name edit rule
		editProjectNameRule: function(projectName) {
			if(this.editProjectDialog.visible==true){
				// document type cannot be empty
				if (projectName == null || projectName == '') {
					this.editProjectDialog.errors.name = true;
					this.editProjectDialog.errorMessages.name = this.lviews.errorProjectText;
					return false;
				}

				let idx = this.projectsConfiguration.projects.findIndex(x => { return x.name.toLowerCase() == projectName.toLowerCase(); });
				if (idx != -1 && (!this.editProjectDialog.item || this.projectsConfiguration.projects[idx].id != this.editProjectDialog.item.id)) {
					this.editProjectDialog.errors.name = true;
					this.editProjectDialog.errorMessages.name = this.lviews.errorProjectExists;
					return false;
				}
				this.editProjectDialog.errors.name = false;
				this.editProjectDialog.errorMessages.name = "";
				return true;
			}
			this.editProjectDialog.errors.name = false;
			this.editProjectDialog.errorMessages.name = "";
			return true;
		},

		/**
		 * Deactivates given document type
		 * @param documentType - document type to deactivate
		 */
		// deactivateDocumentType: function(documentType) {
		// 	documentType.disabled = !documentType.disabled;
		// },

		//LEAVE TYPES METHODS
		adaptLeaveTypesData: function (responseData) {
			this.leaveTypesConfiguration.items = [];
			this.leaveTypesConfiguration.sourceData = [];

			for(let i = 0; i < responseData.length; i++) {
				this.leaveTypesConfiguration.items.push({
					id: responseData[i].id,
					name: responseData[i].name,
					code: responseData[i].code,
                    description: responseData[i].description,
                    color: responseData[i].color,
					bgcolor: responseData[i].bgcolor,
                    active: responseData[i].status==0? true: false,
					deletable: true //TODO
				});

				this.leaveTypesConfiguration.sourceData.push({
					id: responseData[i].id,
					name: responseData[i].name,
					code: responseData[i].code,
                    description: responseData[i].description,
                    color: responseData[i].color,
					bgcolor: responseData[i].bgcolor,
                    active: responseData[i].status==0? true: false,
					deletable: true //TODO
				});
			}
		},
		// fetches leave types data from database
		fetchLeaveTypesData: async function() {
			this.$emit("set-state", "LOADING_OVERLAYER");
			let responsePromise = axios({
				method: "GET",
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/leave-types/",
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			});

			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				this.$emit("set-state", "default");
				this.handleError()
			}
			this.$emit("set-state", "default");
			return response.data;
		},

		saveLeaveTypeChanges(leaveType){
			let data={
				"status": leaveType.active? 0: 1,
				"color": leaveType.color,
				"bgcolor": leaveType.bgcolor,
				"description": leaveType.description
			}
			if(leaveType.name!=null && leaveType.name!=""){
				data.name=leaveType.name
			}
			if(leaveType.code!=null && leaveType.code!=""){
				data.code=leaveType.code
			}
			this.$emit("set-state", "LOADING_OVERLAYER", "Saving changes...");
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/leave-types/"+leaveType.id,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: data
				}).then(() => {
					this.$emit('set-state', 'default');

					this.snackbar.color = 'success';
					this.snackbar.message = "Leave type updated sucessfully";
					this.snackbar.visible = true;
					this.reloadLeaveTypesData()
				})
				.catch((error) => {
					this.$emit("set-state", "default");
					this.handleError(error)
			})
		},

		addNewLeaveType(leaveType){
			let data={
				"status": leaveType.active? 0: 1,
				"description": leaveType.description,
				"name": leaveType.name,
				"code": leaveType.code,
				"color": leaveType.color,
				"bgcolor": leaveType.bgcolor
			}
			this.$emit("set-state", "LOADING_OVERLAYER", "Creating leave type...");
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/leave-types/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: data
				}).then(() => {
					this.$emit('set-state', 'default');

					this.snackbar.color = 'success';
					this.snackbar.message = "Leave type added sucessfully";
					this.snackbar.visible = true;
					this.reloadLeaveTypesData()
				})
				.catch((error) => {
					this.$emit("set-state", "default");
					this.handleError(error)
			})
		},

		deleteLeaveTypeConfirm: function(item){
			this.$emit("set-state", "LOADING_OVERLAYER", "Removing leave type...");
			axios({
				method: "DELETE",
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/leave-types/" + item.id,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				},
			}).then(()=>{
				this.$emit("set-state", "default");
				this.snackbar.color = 'success';
				this.snackbar.message = "Leave type removed sucessfully";
				this.snackbar.visible = true;
				this.reloadLeaveTypesData()
				this.closeLeaveTypeDelete()
			}).catch ((error)=> {
				this.$emit("set-state", "default");
				this.handleError(error)
				this.closeLeaveTypeDelete();
				return;
			})
		},

		/**
		 * Edits existing leave type
		 * @param leaveType - leave type to edit
		 */
		editLeaveType: function(leaveType) {
			this.editLeaveTypeDialog.item = leaveType;
			this.editLeaveTypeDialog.modifiedItem.name = leaveType.name;
			this.editLeaveTypeDialog.modifiedItem.code = leaveType.code;
			this.editLeaveTypeDialog.modifiedItem.description = leaveType.description;
			this.editLeaveTypeDialog.modifiedItem.active = leaveType.active;
			this.editLeaveTypeDialog.modifiedItem.color = leaveType.color;
			this.editLeaveTypeDialog.modifiedItem.bgcolor = leaveType.bgcolor;
			this.editLeaveTypeDialog.visible = true;
		},

		addLeaveType: function() {
			this.editLeaveTypeDialog.modifiedItem.active=true
			this.editLeaveTypeDialog.visible = true;
			if(this.editLeaveTypeDialog.modifiedItem.name==''||this.editLeaveTypeDialog.modifiedItem.name==null){
				this.editLeaveTypeDialog.errors.name=true
			}
			if(this.editLeaveTypeDialog.modifiedItem.code==''||this.editLeaveTypeDialog.modifiedItem.code==null){
				this.editProjectDialog.errors.code=true
			}
		},

		deleteLeaveType (item) {
			this.deleteLeaveTypeDialog.item=item
			this.deleteLeaveTypeDialog.visible=true
		},

		closeLeaveTypeDelete () {
			this.deleteLeaveTypeDialog.item={
				name: null
			}
			this.deleteLeaveTypeDialog.visible=false
		},

		// leave type name edit rule
		editLeaveTypeNameRule: function(leaveTypeName) {
			if(this.editLeaveTypeDialog.visible){
				// document type cannot be empty
				if (leaveTypeName == null || leaveTypeName == '') {
					this.editLeaveTypeDialog.errors.name = true;
					this.editLeaveTypeDialog.errorMessages.name = this.lviews.errorLeaveTypeName;
					return false;
				}

				let idx = this.leaveTypesConfiguration.items.findIndex(x => { return x.name.toLowerCase() == leaveTypeName.toLowerCase(); });
				if (idx != -1 && (!this.editLeaveTypeDialog.item || this.leaveTypesConfiguration.items[idx].id != this.editLeaveTypeDialog.item.id)) {
					this.editLeaveTypeDialog.errors.name = true;
					this.editLeaveTypeDialog.errorMessages.name = this.lviews.errorLeaveTypeExists;
					return false;
				}
				this.editLeaveTypeDialog.errors.name = false;
				this.editLeaveTypeDialog.errorMessages.name = "";
				return true;
			}
			this.editLeaveTypeDialog.errors.name = false;
			this.editLeaveTypeDialog.errorMessages.name = "";
			return true;
		},

		// leave type code edit rule
		editLeaveTypeCodeRule: function(leaveTypeCode) {
			if(this.editLeaveTypeDialog.visible){
				// document type cannot be empty
				if (leaveTypeCode == null || leaveTypeCode == '') {
					this.editLeaveTypeDialog.errors.code = true;
					this.editLeaveTypeDialog.errorMessages.code = this.lviews.errorLeaveTypeCode;
					return false;
				}

				let idx = this.leaveTypesConfiguration.items.findIndex(x => { return x.code.toLowerCase() == leaveTypeCode.toLowerCase(); });
				if (idx != -1 && (!this.editLeaveTypeDialog.item || this.leaveTypesConfiguration.items[idx].id != this.editLeaveTypeDialog.item.id)) {
					this.editLeaveTypeDialog.errors.code = true;
					this.editLeaveTypeDialog.errorMessages.code = this.lviews.errorLeaveTypeExists;
					return false;
				}
				this.editLeaveTypeDialog.errors.code = false;
				this.editLeaveTypeDialog.errorMessages.code = "";
				return true;
			}
			this.editLeaveTypeDialog.errors.code = false;
			this.editLeaveTypeDialog.errorMessages.code = "";
			return true;
		},

		editLeaveTypeCallback: function() {
			let modified = this.editLeaveTypeDialog.modifiedItem;
			// if there's any error, we cannot update project
			if (this.editLeaveTypeDialog.errors.name) {
				return;
			}

			//save modified leave type
			if(this.editLeaveTypeDialog.item){
				modified.id=this.editLeaveTypeDialog.item.id
				if(this.editLeaveTypeDialog.item.name==modified.name){
					modified.name=null
				}
				if(this.editLeaveTypeDialog.item.code==modified.code){
					modified.code=null
				}
				this.saveLeaveTypeChanges(modified)
			}
			//add new project
			else{
				this.addNewLeaveType(modified)
			}

			// reset dialog data and close it
			this.editLeaveTypeDialog.item = null;
			this.editLeaveTypeDialog.modifiedItem = {
				name: null,
				active: null,
				description: null,
				code: null,
				bgcolor: '#eeeeee',
				color: '#cccccc'
			};
			this.editLeaveTypeDialog.errors = {
				name: false,
			}

			this.editLeaveTypeDialog.visible = false;
		},

		changeLeaveTypeStatus(leaveType){
			this.editLeaveTypeDialog.item = leaveType;
			this.editLeaveTypeDialog.modifiedItem.name = leaveType.name;
			this.editLeaveTypeDialog.modifiedItem.code = leaveType.code;
			this.editLeaveTypeDialog.modifiedItem.description = leaveType.description;
			this.editLeaveTypeDialog.modifiedItem.active = !leaveType.active;
			this.editLeaveTypeDialog.modifiedItem.color = leaveType.color;
			this.editLeaveTypeDialog.modifiedItem.bgcolor = leaveType.bgcolor;
			this.editLeaveTypeCallback()
		},

		// Resets edit project dialog and closes it
		cancelEditingLeaveType: function() {
			this.editLeaveTypeDialog.item = null;
			let resetName, resetCode
			if(this.editLeaveTypeDialog.modifiedItem.name===null){
				resetName=""
			}
			else{
				resetName=null
			}
			if(this.editLeaveTypeDialog.modifiedItem.code===null){
				resetCode=""
			}
			else{
				resetCode=null
			}
			this.editLeaveTypeDialog.modifiedItem = {
				name: resetName,
				code: resetCode,
				description: null,
				active: null,
				bgcolor: '#eeeeee',
				color: '#cccccc'
			};

			this.editLeaveTypeDialog.visible = false;

			
			// this.editLeaveTypeDialog.errors.name = false;
			// this.editLeaveTypeDialog.errorMessages.name = "";
			// this.editLeaveTypeDialog.errors.code = false;
			// this.editLeaveTypeDialog.errorMessages.code = "";
		},


		// OTHER METHODS
		handleError(error){
			if (typeof(error.response) === 'undefined'){
						this.snackbar.message = "Application failed to connect to the server. Please check your internet connection."
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
					}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.snackbar.message = "You do not have priviledges to this action";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
					case 429:
						this.snackbar.message = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
					case 400:
						console.log(error.response.data)
						this.snackbar.message = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
					case 500:
						this.snackbar.message = "An internal server error has occured. Please report this message to the administrator.";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
					default:
						this.snackbar.message = "An unknown error has occured. That's all we know";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
				}
		},

		onResize(){
			this.windowWidth = window.innerWidth;
		},

		swatchStyle(color) {
			return {
				backgroundColor: color,
				cursor: 'pointer',
				height: '40px',
				width: '50px',
				border: '1px solid #686868',
				borderRadius: this.editLeaveTypeDialog.colorPicker ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out'
			}
		},

		reloadProjectsData: async function(){
			let projectsData = await this.fetchProjectsData();
			if (projectsData != null){
				this.adaptProjectsData(projectsData);
			}
		},

		reloadLeaveTypesData: async function(){
			let leaveTypesData = await this.fetchLeaveTypesData();
			if (leaveTypesData != null){
				this.adaptLeaveTypesData(leaveTypesData);
			}
		},


		openErrorDialog: function(title, message) {
			this.errorDialog.title = title;
			this.errorDialog.content = message;
			this.errorDialog.visible = true;
		},

		closeErrorDialog: function() {
			this.errorDialog.title = "";
			this.errorDialog.content = "";
			this.errorDialog.visible = false;
		}
	},
}
</script>

<style scoped>

.required:before {
	content:"*";
	color: red;
	margin-right: 5px;
}

.required:after {
	content:"";
}

.groups-table {
	width: 600px;
	margin: 20px 30px auto 30px;
}

@media screen and (max-width: 1100px) {
	.groups-table {
		width: 90%;
	}
}

.table-row-wrapper {
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 1fr 103px 100px;
	font-size: 0.875rem;
	text-align: center;
}

.table-header-list-item{
	background: #cccccc;
	height: 35px;
}

.permissions-header {
	font-weight: bold;
	color: rgba(0,0,0,0.6);
}

.table-wrapper-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.table-wrapper-item-left {
	text-align: left;
}


@media only screen and (max-width: 450px) {
	.table-row-wrapper {
		grid-template-columns: 1fr 48px 40px;
	}
}


.table{
	padding-top: 0;
	overflow: hidden;
	position: relative;
	margin-top: 20px;
	border: 1px solid black;
}
.table-item {
	border-top: 1.5px solid #e0e0e0;
}

.colorFieldWrapper{
	width: 60%;
}

@media only screen and (max-width: 450px) {
	.colorFieldWrapper {
		width: 40%;
	}
}
</style>
